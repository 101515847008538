<template>
    <div class="ticket-config">
        <main-tab-bar
            ref="tabbar"
            :tabitem="tabitem"
            :activename="activename"
            :loading="showTabLoading"
            :is-custom-close-tab="true"
            @closeTab="handleCloseTab"
            @handleTabClick="handleTabClick"
        >
            
            <template v-for="(item) in tabitem.slice(0,3)" :slot="item.label">
                <div :key="item.name" v-if="item.name === activename">
                    <!-- 头部区域 -->
                    <el-row type="flex" class="row-bg top-box" justify="space-between">
                        <el-col :span="6">
                            <el-button size="mini" type="primary" @click="handleAddData">新增</el-button>
                        </el-col>
                        <el-form @submit.native.prevent :inline="true" class="search">
                            <el-form-item label="名称：">
                              <el-input size="mini" clearable v-model="searchKey" @keyup.enter.native="onQuery"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button size="mini" type="primary" icon="el-icon-search" @click="onQuery">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </el-row>

                    <!-- 列表内容 -->
                    <table-comp
                        :loading="showTableLoading"
                        :table-column="tableColumn"
                        :table-data="tableData"
                        :total="tableTotal"
                        :pagenum="pageNum"
                        :pageno="pageNo"
                        :selectType="[]"
                        :hotels="[]"
                        :show-batch-del="true"
                        @editRow="handleEditData"
                        @delRow="handleDeleteData"
                        @batchDel="batchDel"
                        @handlePageChange="handlePageChange"
                        @handleSizeChange="handleSizeChange"
                    />
                </div>
            </template>

            <!-- 编辑页插槽 -->
            <div :slot="tabitem[3].label" v-if="tabitem[3] && activename === tabitem[3].name && form">
                <from-data
                    :show-filed-data="showFieldData"
                    :is-edit="isEdit"
                    :form="form"
                    :rules="rules"
                    :selectdata="{}"
                    @closetForm="handleCloseTab"
                    @submitForm="submitForm"
                />
            </div>
            
        </main-tab-bar>
    </div>
</template>

<script>
import MainTabBar from "@components/content/maintabbar/MainTabBar";
import TableComp from "@components/content/tablecomp/TableComp";
import FromData from "@/views/modelmanage/childecomponent/FromData";

export default {
    name: "RoomConfig",
    components: {
        MainTabBar,
        TableComp,
        FromData
    },
    data() {
        return {
            tabitem: [
                {
                    name: "jrsz",
                    label: "房间类型",
                    directory_code: "roomtype"
                },
                {
                    name: "pxsz",
                    label: "酒店类型",
                    directory_code: "staytype"
                },
                {
                    name: "pxfz",
                    label: "商品类型",
                    directory_code: "commoditytype"
                },
            ],
            activename: "jrsz",
            prevActivename: "",

            searchKey: "",
            
            showTabLoading: false, //标签页加载中
            showTableLoading: false, //表格加载中

            fieldList: [], //字段列表

            tableColumn: [],
            tableData: [],
            pageNum: 20,
            pageNo: 1,
            tableTotal: 0,

            showFieldData: [],
            form: null,
            rules: {}
        }
    },
    computed: {
        directory_code() {
            const target = this.tabitem.find(item => item.name === this.activename);
            return target.directory_code || null;
        }
    },
    created() {
        this.getResourcefield(this.directory_code);
        this.queryDataOfResource(this.directory_code, {});
    },
    methods: {
        initBaseData() {
            this.searchKey = "";

            this.fieldList = [];
            this.tableColumn = [];
            this.showFieldData = [];
            
            this.tableData = [];
            this.pageNum = 20;
            this.pageNo = 1;

            this.prevActivename = "";
        },

        handleTabClick(tab) {
            // 防止重复点击
            if(tab.name === this.activename) return;

            // 切换标签页
            this.activename = tab.name;
            this.$refs.tabbar.activeName = tab.name;

            // 不是编辑页跳同directory_code页或非跳转编辑页 重置数据并关闭编辑页
            if(tab.name === this.prevActivename || tab.name === "editpage") return;
            this.initBaseData();
            this.getResourcefield(this.directory_code);
            this.queryDataOfResource(this.directory_code, {});
            this.tabitem.length = 3;
        },
        handleCloseTab() {
            this.form = null;

            if(this.activename === "editpage"){
                // 选中编辑页后关闭编辑页
                this.activename = this.prevActivename || "jrsz";
                this.$refs.tabbar.activeName = this.prevActivename || "jrsz";
            }

            this.tabitem.length = 3;
            this.prevActivename = "";
        },

        // 点击添加按钮
        handleAddData() {
            this.prevActivename = this.activename;

            this.form = this.showFieldData.reduce((prev,item) => {
                prev[item.en_name] = item.show_type == "2" ? 1 : "";
                return prev;
            }, {});

            if (this.form.images) this.form.images = [];

            if (this.hasTabName("editpage")) {
                this.tabitem[3].label = "新增";
            } else {
                this.tabitem.push({
                    name: "editpage",
                    label:"新增",
                    closable: true,
                });
            }

            this.setRules();
            this.isEdit = false;
            this.activename = "editpage";
            this.$refs.tabbar.activeName = "editpage";
        },

        // 查询
        onQuery() {
            this.tableData = [];
            this.queryDataOfResource(this.directory_code, this.getQueryParams());
        },
        getQueryParams() {
            let params = {};
            let key = this.tableColumn[0]["prop"];
            params[key] = this.searchKey;
            return params;
        },

        // 点击编辑
        handleEditData(row, field) {
            this.prevActivename = this.activename;

            this.form = JSON.parse(JSON.stringify(row));

            if (this.form.images) {
                this.form.images = JSON.parse(this.form.images) || [];
            }

            if (this.hasTabName("editpage")) {
                this.tabitem[3].label = row[field] + "-编辑";
            } else {
                this.tabitem.push({
                    name: "editpage",
                    label: row[field] + "-编辑",
                    closable: true,
                });
            }

            this.setRules();
            this.isEdit = true;
            this.activename = "editpage";
            this.$refs.tabbar.activeName = "editpage";
        },
        // 判断标签页是否已经打开
        hasTabName(name) {
            return this.tabitem.find((val) => val.name === name);
        },
        // 点击删除
        handleDeleteData(row) {
            this.deleteDataOfResource(row.row_id,() => {
                if(this.form.row_id && row.row_id == this.form.row_id){
                    this.form = null;
                    this.prevActivename = "";
                    this.tabitem.length = 3;
                }
            });
        },
        // 批量删除
        batchDel(row) {
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let ids = row.map(item => item.row_id);
                this.deleteDataOfResource(ids.toString(), () => {
                    if(this.form && ids.find(id => id === this.form.row_id)) {
                        this.form = null;
                        this.prevActivename = "";
                        this.tabitem.length = 3;
                    }
                });
            }).catch(() => {});
        },

        // 点击保存
        submitForm(fromData) {
            const target = this.tabitem.find(item => item.name === this.prevActivename);
            fromData = JSON.parse(JSON.stringify(this.clearSubmitData(fromData)));

            if (this.isEdit) {
                this.modifyDataOfResource(target.directory_code, fromData, () => {
                    this.pageNo = 1;
                    this.tableData = [];
                    this.queryDataOfResource(target.directory_code, {});
                    this.handleCloseTab();
                });
            } else {
                this.addDataOfResource(target.directory_code, fromData, () => {
                    this.pageNo = 1;
                    this.tableData = [];
                    this.queryDataOfResource(target.directory_code, {});
                    this.handleCloseTab();
                });
            }
        },
        // 处理上传前的表单，int类型为空都写为0，删除create_time
        clearSubmitData(fromData) {
            delete fromData.create_time;
            return fromData;
        },

        setRules() {
            if(this.activename === "jrsz") {
                this.rules = {
                    name: [{
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    }],
                    start_date: [{
                        required: true,
                        message: "请选择开始日期",
                        trigger: "blur",
                    }],
                    end_date: [{
                        required: true,
                        message: "请选择结束日期",
                        trigger: "blur",
                    }]
                }
            }else if(this.activename === "pxsz"){
                this.rules = {
                    name: [{
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    }]
                }
            }else if(this.activename === "pxfz"){
                this.rules = {
                    name: [{
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    }]
                }
            }
        },

        // 分页
        handlePageChange(val) {
            this.pageNo = val;
            this.tableData = [];
            this.queryDataOfResource(this.directory_code, this.getQueryParams());
        },
        // 切换分页大小
        handleSizeChange(val) {
            this.pageNum = val;
            this.tableData = [];
            this.queryDataOfResource(this.directory_code, this.getQueryParams());
        },

        getResourcefield(directory_code)  {
            this.showTabLoading = true;

            this.$Request({
                method: "get",
                url: this.$Api.getResourcefield,
                params: {
                    directory_code,
                },
            }).then(res => {
                this.fieldList = res.data.list;

                let tableColumn = [];
                let showFieldData = [];

                this.fieldList.map(item => {
                    if(item.show_type != 0) showFieldData.push(item)
                    if(item.is_intable == 1) {
                        let isFirst = tableColumn.length ? false : true;
                        tableColumn.push(this.getTableColItem(item, isFirst))
                    }
                })

                tableColumn.push(this.getTableColLastItem());
                this.tableColumn = tableColumn;
                this.showFieldData = showFieldData;
            }).finally(() => {
                this.showTabLoading = false;
            })
        },
        getTableColItem({name,en_name,show_type}, isFirst) {
            let column = {
                label: name,
                prop: en_name,
                show_type: show_type
            }
            if(isFirst){
                column.fristEnten = true; //首列点击
                column.func = "editRow";  //点击的方法
            }
            return column;
        },
        getTableColLastItem() {
            return {
                isAction: true,
                fixed: "right",
                label: "操作",
                //操作的按钮与方法
                actionItem: [
                    {
                        text: "编辑", //按钮的文本
                        icon: "el-icon-edit",
                        type: "primary", //按钮类型
                        func: "editRow", //按钮的方法
                    }
                ],
                //带确认的按钮
                popconfirmitem: [
                    {
                        text: "删除",
                        icon: "el-icon-delete",
                        func: "delRow",
                        title: "确定删除这条数据吗？",
                    },
                ],
            }
        },
        
        // 获取表格数据
        queryDataOfResource(directory_code, data) {
            this.showTableLoading = true;
            this.$Request({
                method: "get",
                url: this.$Api.queryDataOfResource,
                params: {
                    data,
                    pageNo: this.pageNo,
                    pageNum: this.pageNum,
                    directory_code,
                },
            }).then((res) => {
                this.tableData = res.data;
                this.tableTotal = res.total;
            }).finally(() => {
                this.showTableLoading = false;
            })
        },
        // 删除表格数据
        deleteDataOfResource(row_id, callback){
            this.$Request({
                method: "get",
                url: this.$Api.deleteDataOfResource,
                params:{
                    data: { row_id },
                    directory_code: this.directory_code,
                },
            }).then((res) => {
                if(res.errCode == 0){
                    this.$message({ message: "删除成功", type: "success" });
                    this.queryDataOfResource(this.directory_code, this.getQueryParams());
                    callback && callback();
                }
            }).catch(() => {});
        },
        // 修改数据
        modifyDataOfResource(directory_code, fromData, callback) {
            this.$Request({
                method: "post",
                url: this.$Api.modifyDataOfResource,
                data: { 
                    data: fromData, 
                    directory_code
                },
            })
            .then((result) => {
                if (result.errCode == 0) {
                    this.$message({ message: "修改成功", type: "success" });
                    callback && callback()
                }
            }).catch(() => {});
        },
        // 添加数据
        addDataOfResource(directory_code, fromData, callback) {
            this.$Request({
                method: "post",
                url: this.$Api.addDataOfResource,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: { 
                    data: fromData, 
                    directory_code 
                },
            }).then((res) => {
                if (res.errCode == 0) {
                    this.$message({ message: "新增成功", type: "success"});
                    callback && callback()
                }
            }).catch(() => {});
        },
    }
}
</script>